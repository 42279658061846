<template>
  <div class="sign-sale">
    <div class="sign-sale-header">
        <h1 v-if="!is_catalog_signs && clone_sign?.allClients!='false'">מבצע</h1>
        <h1 v-else>מבצע לחברי מועדון</h1>
        <h1 v-if="is_catalog_signs">רוסמן מרקט</h1>
        <div class="logo">
            <img src="../../../../assets/images/rosman-market-new.png">
        </div>
        <div @click="handle_duplicate_sign(sign)" v-if="!is_catalog_signs" class="duplicate_btn no-see-in-print">
            <i class="material-icons duplicate_icon">control_point_duplicate</i>
        </div>
        <div v-if="!is_catalog_signs && sign.is_duplicate" @click="handle_remove_duplicate_sign(sign)" class="remove_duplicate_btn no-see-in-print">
            <i class="material-icons remove_duplicate_icon">delete</i>
        </div>
    </div>
    <div class="sign-sale-input-texts">
        <div class="he">
            <el-input :input-style="input_style" style="width:100%;" v-if="!is_sales_print" v-model="clone_sign.RemarkForPrint" maxlength="35" placeholder="שם המוצר בעברית" />
            <h1 v-if="is_sales_print" :style="style_computed_font_text(clone_sign?.RemarkForPrint,45)">{{clone_sign?.RemarkForPrint}}</h1>
            <!-- <div v-if="is_catalog_signs" class="add_translation no-see-in-print">
                <el-button @click="handle_translate_to_ru(clone_sign?.RemarkForPrint)" type="primary">תרגם</el-button>
            </div> -->
        </div>
        <!-- <div class="ru" v-if="clone_sign.ru_name?.length>=0 && !is_catalog_signs">
            <el-input :input-style="input_style" style="width:100%;" v-if="!is_sales_print" v-model="clone_sign.ru_name" maxlength="38" placeholder="שם המוצר ברוסית" />
            <h1 v-if="is_sales_print" :style="style_computed_font_text(clone_sign.ru_name,30)">{{clone_sign.ru_name}}</h1>
        </div>
        <div class="ru" v-if="clone_sign.ru_name?.length>=0  && is_catalog_signs && translate_action">
            <el-input :input-style="input_style" style="width:100%;" v-if="!is_sales_print" v-model="clone_sign.ru_name" maxlength="38" placeholder="שם המוצר ברוסית" />
            <h1 v-if="is_sales_print" :style="style_computed_font_text(clone_sign.ru_name,30)">{{clone_sign.ru_name}}</h1>
        </div>
        <div class="ru" v-if="clone_sign.ru_name == null && !is_catalog_signs">
            <h1 style="color:red;" :style="style_computed_font_text(clone_sign.ru_name,30)"><span>{{ loadingDots }}</span> Пожалуйста, подождите, переводите </h1>
        </div>
        <div class="ru" v-if="clone_sign.ru_name == null && is_catalog_signs && translate_action">
            <h1 style="color:red;" :style="style_computed_font_text(clone_sign.ru_name,30)"><span>{{ loadingDots }}</span> Пожалуйста, подождите, переводите </h1>
        </div> -->
        <i v-if="is_catalog_signs"  @click="handle_show_items_list" class="material-icons float-icon add-icon">add</i>
    </div>
    <div v-if="!is_catalog_signs" class="sign-sale-contents">
        <div class="col-2 col">
            <el-input :input-style="input_style" type="number" v-if="!is_sales_print" v-model="clone_sign.amount" placeholder="הזן מספר" />
            <h1 v-if="is_sales_print && Number(sign?.amount) > 0.01"> {{clone_sign.amount}} <span style="font-size:40px;">יח'</span></h1>
            <h1 v-else-if="is_sales_print && Number(sign?.amount) < 1">{{clone_sign.amount}} <span style="font-size:40px;">ק"ג </span></h1>
        </div>
        <div class="col-1 col">
            <h1>ב-</h1>
        </div>
        <div class="col-2 col">
            <el-input :input-style="input_style" type="number" v-if="!is_sales_print" v-model="clone_sign.sum_price" placeholder="הזן מספר" />
            <h1 v-if="is_sales_print">{{Number(clone_sign.sum_price).toFixed(1)}}<small> <span style="font-size:40px;"> &#8362;</span></small></h1>
        </div>
    </div>
    <div v-else class="sign-sale-contents catalog">
        <div v-if="!is_sales_print" class="col-2 col">
            <el-input :input-style="input_style" type="number" v-model="clone_sign.price" placeholder="מחיר" />
        </div>
        <div v-if="!is_sales_print" class="col-1 col">
            <h1>ל-</h1>
        </div>
        <div v-if="!is_sales_print" class="col-2 col">
            <el-input :input-style="input_style"  v-model="clone_sign.unit" placeholder="הזן יחידת מידה" />
        </div>
        <h1 v-if="is_sales_print">
            {{clone_sign.price}} &#8362; <span>ל - {{clone_sign.unit}}</span>
        </h1>
    </div>
    <div v-if="clone_sign?.products?.length>0" class="sign-sale-footer">
        <div class="col-2 col barcodes">
            <h1 data-fixed>
                ברקוד: 
            </h1>
            <h1 data-slice v-if="clone_sign?.Items?.length > 0">
                {{clone_sign.products.map(product => product.barcode.slice(-4)).slice(0,2).join(",")}}<span v-if="clone_sign?.products?.length > 2">,...</span>
            </h1>
            <h1 data-slice v-if="clone_sign?.barcode">{{clone_sign?.barcode.slice(-4)}}</h1>
        </div>
        <div class="col-1 col small">
            <small v-if="clone_sign?.products?.length > 2">סדרה</small>
        </div>
        <div v-if="!is_catalog_signs" class="col-2 col price">
             <el-input v-if="!is_sales_print" style="font-size: 15px;" :input-style="{...input_style,'font-size':'20px'}"  v-model="clone_sign.original_price" placeholder="הזן יחידת מידה" />
             
            <small v-else>מחיר רגיל: {{clone_sign.original_price}}</small>
            <!-- <small v-else-if="clone_sign?.price && sign?.amount=='0.01'">מחיר רגיל: {{(Number(clone_sign?.price)/10).toFixed(2)}}  &#8362;</small>
            <small v-else-if="clone_sign.products?.length>0 && clone_sign.products[0]?.price && sign?.amount!='0.01'">מחיר רגיל: {{Number(clone_sign.products[0]?.price).toFixed(2)}} &#8362; </small>
            <small v-else-if="clone_sign.products?.length>0 && clone_sign.products[0]?.price && sign?.amount=='0.01'">מחיר רגיל: {{(Number(clone_sign.products[0]?.price)/10).toFixed(2)}} &#8362; </small>
            <small>ל - {{sign.amount!='0.01'?'1 יח\'':'100 גרם'}}</small> -->
        </div>
    </div>
    <div v-else class="sign-sale-footer item-error">
        <h1>
            פריט לא הוקם טוב בקומקס נא לפנות למחלקת סחר
        </h1>
    </div>
    <div v-if="!is_catalog_signs" class="prormo-limit">
        <p>{{clone_sign.limitations.filter(lim=> lim && lim.trim() && lim!='לחברי מועדון בלבד').join(', ')}}</p>
    </div> 
    <div v-if="show_items_list" class="choos-item slide-in-top">
        <ItemsList @back="show_items_list=false; clone_sign={};" @item_choosen="handle_item_choosen" />
    </div> 

    <!-- <ErrorTranslateSign 
        v-if="show_error"
        :item_name="clone_sign.RemarkForPrint" 
        @try_again="init"
        @translate_manuel="handle_translate_manuel"
    />   -->
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {is_sales_print} from '../Scripts/scripts'
import {is_catalog_signs} from './Deals/Scripts/store'
import { computed, defineAsyncComponent, onBeforeUnmount } from 'vue'
import {translate_hebrew_to_russian} from '../components/Deals/Scripts/script'
import {printer_amount,handle_duplicate_sign,handle_remove_duplicate_sign,is_deleted} from '../components/Deals/Scripts/store'
import {slide_pop_error} from '../../../../Methods/Msgs'
import { ElNotification } from 'element-plus'


export default {
    props:['sign'],
    components:{
        ItemsList:defineAsyncComponent(() => import('../ItemsList.vue')),
        ErrorTranslateSign:defineAsyncComponent(() => import('./Errors/ErrorTranslateSign.vue'))
    },
    setup(props){

        const style_computed_font_text = (text,pixel)=>{
            if(text && pixel){
                const words_counts = text.split(' ').length
                if(words_counts >= 5){
                    console.log('checkL:');
                    return { fontSize: `${pixel}px` };
                }
            }
            return {}
        }

        const show_error = ref(false)

        const translate_action = ref(false)
        
        const dotsCount = ref(0);

        // Computed property to display loading dots
        const loadingDots = computed(() => {
            const dots = ['.', '..', '...'];
            return dots[dotsCount.value % dots.length];
        });

        // Increment dotsCount every second
        const interval = setInterval(() => {
            dotsCount.value++;
        }, 1000);

        const handle_translate_to_ru = async(hebrew) => {
            try{
                if(!hebrew){
                    throw new Error('עליך להזין שם המוצר בעברית תחילה!')
                }else{
                    clone_sign.value.ru_name = null
                    translate_action.value = true
                    clone_sign.value.ru_name = await translate_hebrew_to_russian(hebrew)
                }
            }catch(err){
                clone_sign.value.ru_name = null
                translate_action.value = false
                slide_pop_error(err.message)
            }
        }

        const handle_show_items_list = () => {
            show_items_list.value=true
            clone_sign.value.ru_name = null
            clone_sign.value.ru_name = null
            translate_action.value = false
        }


        const ru_loader = ref(false)
        const show_items_list = ref(false)

        const clone_sign = ref({})

        const input_style = ref({
            'font-size':'45px',
            'color':'black',
            'margin-top':'10px',
            'text-align': 'center',
            'border': '2px solid black',
            'border-left':'0',
            'border-right':'0',
            'border-top':'0',
        })

        const handle_item_choosen = (item) => {
            show_items_list.value = false
            clone_sign.value = {
                RemarkForPrint:item.Name,
                barcode:item.barcode,
                price:item.amount=='0.01'?(Number(item?.price)/10).toFixed(2):item?.price,
                amount:item.amount,
                unit:item.amount=='0.01'?'100 גרם':'יח\''
            }
        }

        const handle_translate_manuel = () => {
            clone_sign.value.ru_name='' 
            show_error.value = false
            printer_amount.value.push(1)
        }

        const init = async() => {
            try{
                show_error.value = false
                if(props.sign){
                    clone_sign.value  = JSON.parse(JSON.stringify(props.sign))
                    if(Number(props.sign.amount) < 1){
                        clone_sign.value.amount = Number(props.sign.amount * 100)
                        clone_sign.value.sum_price = (Number(clone_sign.value.sum_price)).toFixed(2)
                    }
                    if(!is_catalog_signs.value && !props.sign.ru_name){
                        ru_loader.value = false
                        // props.sign.ru_name = await translate_hebrew_to_russian(clone_sign.value.RemarkForPrint)
                        if(!is_deleted.value) printer_amount.value.push(1)
                        clone_sign.value.ru_name = props.sign.ru_name
                        ru_loader.value = true
                    }
                    else if(!is_catalog_signs.value && props.sign.ru_name && !is_deleted.value){
                        printer_amount.value.push(1)
                    }
                }
            }catch(err){
                console.log(err.message);
                ElNotification({
                    title: 'נא לשים לב',
                    message: `התרגום לפריט/למבצע ${clone_sign.value.RemarkForPrint} לא הצליח, נא לגלול לשלט לנסות שנית או לתרגם ידנית! `,
                    duration: 0,
                    type: 'warning',
                })
                show_error.value = true
            }
        }

        onBeforeUnmount(()=>{
            if(interval){
                clearInterval(interval);
            }
        })

        init()
        
        return{
            init,
            is_sales_print,
            input_style,
            clone_sign,
            is_catalog_signs,
            show_items_list,
            handle_item_choosen,
            ru_loader,
            loadingDots,
            translate_action,
            handle_translate_to_ru,
            handle_show_items_list,
            handle_translate_manuel,
            show_error,
            style_computed_font_text,
            handle_duplicate_sign,
            handle_remove_duplicate_sign
        }
    }
}
</script>

<style scoped>
    .sign-sale{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .sign-sale-header{
        position: relative;
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        border-bottom: 1px solid black;
    }
    .duplicate_btn,.remove_duplicate_btn{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        user-select: none;
    }
    .duplicate_icon,.remove_duplicate_icon{
        cursor: pointer;
    }
    .remove_duplicate_btn{
        right: 40px;
    }
    .remove_duplicate_icon{
        color: var(--danger);
    }
    .prormo-limit{
        height: 10%;
        width: 100%;
        font-size: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .logo{
        position: absolute;
        width: 100px;
        height: 100%;
        top: 0;
        left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .logo img{
        max-width: 100%;
        max-height: 100%;
    }
    .sign-sale-input-texts{
        position: relative;
        width: 100%;
        height: 30%;
    }
    .add-icon,.clean-icon{
        color: var(--success);
        cursor: pointer;
        font-size: 50px;
        user-select: none;
    }
    .float-icon{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
    }
     .sign-sale-input-texts .he,.sign-sale-input-texts .ru{
        position: relative;
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        text-align: center;
    }
    .sign-sale-input-texts .ru{
        font-size: 10px;
    }
    .add_translation{
        position: absolute;
        top: 50%;
        left: 50px;
        transform: translateY(-50%);
        width: 100px;
    }
    
    .float-icon,.save-icon,.add-deal-icon{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 50px;
    }
    .save-icon,.add-deal-icon{
        right: unset;
        left: 50px;
        color: var(--blue) !important;
    }
    .add-icon,.clean-icon,.save-icon,.add-deal-icon{
        color: var(--success);
        cursor: pointer;
        font-size: 35px;
        user-select: none;
    }
    .add-deal-icon::before{
        content: 'בחירת מבצע';
        font-size: 15px;
        position: absolute;
        bottom: 100%;
        left: -50%;
        font-family: 'Rubik', sans-serif; 
    }
    .save-icon::before{
        content: 'שמירת מבצע';
        font-size: 15px;
        position: absolute;
        bottom: 100%;
        left: -50%;
        font-family: 'Rubik', sans-serif;
    }
    .clean-icon{
        color: var(--danger);
    }
    .clean-icon::before{
        content: 'נקה';
        font-size: 15px;
        position: absolute;
        bottom: 115%;
        right: 50%;
        transform: translateX(50%);
        font-family: 'Rubik', sans-serif;
    }
    .add-icon::before{
        content: 'בחירת פריט';
        font-size: 15px;
        position: absolute;
        bottom: 100%;
        right: 50%;
        transform: translateX(50%);
        font-family: 'Rubik', sans-serif;
    }

    .sign-sale-contents{
        width: 100%;
        height: 40%;
        display: flex;
        font-size: 50px;
    }
    .sign-sale-contents.catalog{
        justify-content: center;
        align-items: center;
    }
    .col{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .col-1{
        width: 10%;
        height: 100%;
    }
    .col-2{
        width: 45%;
        height: 100%;
    }
    .col.barcodes{
        justify-content:unset;
        padding: 0 5px;
        width: 40%;
    }
    .col.small{
        font-size: unset; 
        text-align: center;
        width: 30%;
        font-size: 25px;
    }
    .col.price{
        width: 30%;
        font-size: 25px;
        flex-direction: column;
    }
     .col.price > small{
        font-weight: 500;
        font-size: 15px;
     }
    [data-slice]{
        white-space: nowrap; 
        overflow: hidden;
        margin-right: 5px;

    }
    [data-fixed]{
        display: flex;
        align-items: center;
    }
    .sign-sale-footer{
        width: 100%;
        height: 10%;
        display: flex;
    }
    .sign-sale-footer.item-error{
        justify-content: center;
        align-items: center;
        color: red;
    }
    .choos-item,.choos-deal{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--main);
        z-index: 1000;
    }

    .slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
</style>